<template>
  <div>
    <div class="d-flex align-center mb-5">
      <back-square url="SelectRequestType" />
      <div>
        <h5 class="text-h5 font-weight-bold mb-1">Please, pick a request type</h5>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0"
        ></v-breadcrumbs>
      </div>
    </div>

    <preloader v-if="gettingProducts" />

    <!-- PRODUCTS -->
    <v-alert type="info" v-if="!gettingProducts && !products.length" border="left" text>
      No product found
    </v-alert>

    <div class="mt-5 d-flex gap-10 flex-wrap" v-if="!gettingProducts && products.length">
      <type-card
        v-for="product in orderBy(products, 'name', 'asc')"
        :key="product.id"
        @selected="$router.push({ name: 'RequestForm', params: { id: $route.params.id, product: product.id } })"
        :type="product"
        noneRoute
        action
      ></type-card>
    </div>
  </div>
</template>

<script>
import db from '@/firebase/init'
import orderBy from 'lodash/orderBy'

import TypeCard from './components/TypeCard.vue'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      orderBy,
      products: [],
      gettingProducts: null, 
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    breadcrumbs: function () {
      return [
        {
          to: { name: 'SelectRequestType' },
          text: 'Request Types',
          disabled: false,
          exact: true,
          link: true,
        },
        {
          text: this.$store.getters['types/getType'](this.$route.params.id).name,
          to: { name: 'SelectRequestProduct', params: { id: this.$route.params.id } },
          disabled: false,
          exact: true,
          link: true,
        },
      ]
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    TypeCard
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    getProducts() {
      this.gettingProducts = true

      db.collection('types')
      .doc(this.$route.params.id)
      .collection('products')
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(doc => {
            let data = doc.data()
            data.ref = doc.ref
            data.id = doc.id
            this.products.push(data)
          })
        }

        this.gettingProducts = false
      })
      .catch(error => {
        console.log(error.message)
        this.$store.dispatch('showError')
        this.gettingProducts = false
      })
    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getProducts()
  }
}
</script>